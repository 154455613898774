import React from "react";
import "./homepage.css";

import heroimg from "../../images/hero.png";

import { Link } from "react-router-dom";


import Scrolltotop from "../../components/Scrolltotop";


import psychologist from "../../images/Group 3.png";
import psyctrist from "../../images/Group 4.png";
import counsellinginst from "../../images/Group 5.png";
import educationalinst from "../../images/Group 6.png"

function Homepage() {
  return (<>
  <Scrolltotop/>
  <div className="bgimage"  alt="bgimage" >
    <div className="hero">
      
      
    

      <div className="contentcontainer">
        <div className="content-left">
          <h1>
            Where Mindfulness
            <br />
            Meets Digital Grace
          </h1>
          <h3>
            Embark on a transformative experience towards a balanced life, where
            we prioritize your well-being while safeguarding your individual
            identity. Our journey offers compassionate care, ensuring not just
            physical health but also emotional support, fostering a holistic
            sense of fulfillment. Join us in this commitment to a life that
            honors your uniqueness and nurtures your overall well-being
          </h3>
        </div>
        <div className="content-right">
          <img className="heroimg" src={heroimg} alt="Hero" />
        </div>
      </div>
    </div>
    <div className="partners">
      <div className="partner-container">
        <h2 className="partner-heading">Exploring creative partnerships</h2>
        <div className="partner-buttons">
          <ul>
          <div className="psychologist"> <Link to="/Psychologist">
            <img  className="button" src={psychologist} alt="Psychologist Badge" /></Link>
            <h2> Psychologist</h2>
          
          </div>
          <div className="psychatrist">
          <Link to="/psycatrist">
          <img  src={psyctrist} alt="Psychologist Badge"/>
          </Link>
          <h2> Psychatrist</h2>
          
          
          </div>
          <div className="counselling-inst">
         <Link to="/Counselling_inst">
            <img  src={counsellinginst} alt="Psychologist Badge"/>
            </Link><h2> Counselling Institutions</h2>
          
            </div>
          <div className="educational-inst">
          <Link to="/Educational_inst">
            <img  src={educationalinst} alt="Psychologist Badge"/>
            </Link><h2> Educational Institutions</h2>
            </div>
          </ul>

        </div>
        <p>Join us in redefining student well-being through creative partnerships with psychologists, psychiatrists, counseling, and educational institutions. Together, we're committed to fostering resilience, self-awareness, and emotional health, shaping a brighter future for students.</p>
  </div>
    </div>
    
    
    </div>

    </>
    
  );
}

export default Homepage;

    

