import { useEffect } from 'react';

function Scrolltotop () {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null; // Custom hooks typically don't return JSX
}

export default Scrolltotop;
