import React from "react";
import "./Contact.css";
import cicon from "../../images/phone-call-svgrepo-com.svg";
import waicon from "../../images/whatsapp-svgrepo-com.svg";
import Scrolltotop from "../../components/Scrolltotop";

const Contact = () => {
  return (
    <>
    <Scrolltotop/>
      <div className="contact-container">
        <div className="contactbg">
          <div className="contact-content">
            <h1>Contact us</h1>
            <p>Let's delve deeper into your project – your ideas, goals, and aspirations.<br/> Our team is eager to engage in meaningful discussions to understand your vision better. Reach out, and let's embark on this journey together.</p>
            
             
          </div>
           <div className="box-container">
                <div className="callicon">
                  <a href="tel: +918848086603">
                  <img className="cicon" src={cicon}/>  </a>
                  <h5>Call us </h5>
                  <p>Don't wait online, get immediate answers and expert advice by phone. Our knowledgeable representatives are ready to assist you. Call now!</p> 
                
                </div>
                <div className="watsapp-icon">
                <a href="https://wa.me/+918848086603">
                  <img className="waicon" src={waicon}/></a>
                  <h5>Whatsapp us </h5>
                  <p>Skip the wait and get help immediately! Chat with us on WhatsApp for a faster and more efficient way to get the support you need.</p> 
                </div>
            
            </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
