import React from "react";
import "./Counselling_inst.css";

import heroimg from "../../images/Counselling_inst.png";
import {Link} from "react-router-dom";

import bottomimg from "../../images/Group 76.png"
import Solution from "../../components/solution/Solution";
import Scrolltotop from "../../components/Scrolltotop";

const Counselling_inst=()=>{
    return(<>

       <Scrolltotop/>
        <div className="Counselling_inst-hero">
           
            
             <div className="Counselling_inst-image"  alt="bgimage" >
        
          <div className="contentcontainer">
            <div className="content-left">
              <h1>
              Transforming Student Well-being: 
                <br />
                
                A Revolution in Emotional Wellbeing
              </h1>
              <h3>
              Join our collaborative initiative with educational institutions and counseling centers to boost emotional well-being. Together, we empower individuals by providing resources for their mental health journey. Contribute to this unified effort as counseling centers unite to make a meaningful impact on emotional well-being in communities.
              </h3>
            </div>
            <div className="content-right">
              <img className="heroimg" src={heroimg} alt="Hero" />
            </div>
          </div>
          
        
        
       
        
        <Solution/>
        <div className="contentcontainer">
            <div className="content-left-bottom">
            
              <h3>
              Are you an educational institution committed to the emotional well-being of your students? Look no further! At Mtherapy, we're establishing partnerships with institutions like yours to create a network of dedicated professionals contributing to our shared mission of fostering student mental well-being.
              </h3>
            </div>
            <div className="content-right-bottom">
                <div className="rectangle" >
              <img className="heroimg" src={bottomimg} alt="Hero" /></div>
            </div>
            
          </div>
        

          <div class="call">
  <div class="call-text">
    <h2>We look forward to welcoming you to our growing team at <br/>MTherapy!
      <br/><br />Connect with us and be a part of something truly impactful</h2>
  </div>
  <div className="button-container">
   <Link className="contact-button" to="/contact">Contact Us</Link>  </div>
   </div>
       
        </div></div></>
    );
};
export default Counselling_inst;