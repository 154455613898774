import React from "react";
import "./Psycatrist.css";
import heroimg from "../../images/Psycatrist.png";
import {Link} from "react-router-dom";
import Scrolltotop from "../../components/Scrolltotop";
import bottomimg from "../../images/Group 73.png"
import Solution from "../../components/solution/Solution";

const Psycatrist=()=>{
    return(<>

       <Scrolltotop/>
        <div className="psycatrist-hero">
           
            
             <div className="psycatrist-image"  alt="bgimage" >
        
          <div className="contentcontainer">
            <div className="content-left">
              <h1>
              Transforming Student Well-being: 
                <br />
                
                A Revolution in Emotional Wellbeing
              </h1>
              <h3>
              Exploring the realm for psychiatrists<br />
fueled by passion and dedication.
              </h3>
            </div>
            <div className="content-right">
              <img className="heroimg" src={heroimg} alt="Hero" />
            </div>
          </div>
          
        </div>
        
         <div className="bgimage2">
        
        <Solution/></div>
        <div className="contentcontainer">
            <div className="content-left-bottom">
            
              <h3>
              Are you a dedicated and passionate psychatrist eager to make a positive impact? Look no further! At Mtherapy, we're building a team of talented professionals to contribute to our mission of promoting mental well-being.
              </h3>
            </div>
            <div className="content-right-bottom">
                <div className="rectangle" >
              <img className="heroimg" src={bottomimg} alt="Hero" /></div>
            </div>
            
          </div>
        

          <div class="call">
  <div class="call-text">
    <h2>We look forward to welcoming you to our growing team at <br/>MTherapy!
      <br/><br />Connect with us and be a part of something truly impactful</h2>
  </div>
  <div className="button-container">
   <Link className="contact-button" to="/contact">Contact Us</Link>  </div>
   </div>
       
        </div></>
    );
};
export default Psycatrist;