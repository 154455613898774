import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Nav = () => {
  const showSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = 'flex';
  };

  const hideSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = 'none';
  };

  return (
    <nav>
      <ul className="sidebar">
        <li onClick={hideSidebar}>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="26"
              viewBox="0 96 960 960"
              width="26"
            >
              <path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg>
          </Link>
        </li>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact us</Link></li>
      </ul>
      <ul>
        <li><Link className='logo' to="/">MTherapy</Link></li>
        <li className="hideOnMobile"><Link to="/">Home</Link></li>
        <li className="hideOnMobile"><Link to="/about">About</Link></li>
        <li className="hideOnMobile"><Link to="/contact">Contact us</Link></li>
        <li className="menu-button" onClick={showSidebar}>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="26"
              viewBox="0 96 960 960"
              width="26"
            >
              <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
            </svg>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
