import React from 'react';
import "./Solution.css";
import b1 from "../../images/b1.png";
import b2 from "../../images/b2.png";
import b3 from "../../images/b3.png";
import b4 from "../../images/b4.png"

const Solution = ()=> {
    return(


<div className='sol-container'>
    <h1 className='sol-heading'> Our Operational Focus</h1>
    <div className='sol-box'>
        <div className='sol-box-child'>
            <img className='boximg1'src={b1} alt="boximg"/>
            <div className='box-content'>
            <h5> Proposed Resolution:</h5><p>
Tackling this intricate issue demands a deliberate, long-term strategy. A pivotal initial step involves fortifying counseling cells within educational institutions, serving as impartial hubs with a substantial caseload, facilitating the wide implementation of potential solutions.</p>
        </div></div>

        <div className='sol-box-child'>
            <img className='boximg1'src={b2} alt="boximg"/>
            <div className='box-content'>
            <h5> Innovative Support System:</h5><p>
           
Our system extends counseling cells into a virtual care grid, ensuring students can securely share concerns with protected identities. Using a Chatbot, it connects them with suitable counselors for a confidential initial interaction.
</p></div>
        </div>

        <div className='sol-box-child'>
            <img className='boximg1'src={b3} alt="boximg"/>
            <div className='box-content'>

            <h5> Lifetime Care:</h5><p>
            
Our comprehensive support remains available as long as needed, potentially becoming a lifelong resource for students facing mental health challenges. Join us in reshaping mental health support in educational institutions, offering a lifeline to those in need.</p>
        </div></div>


        <div className='sol-box-child'>
            <img className='boximg1'src={b4} alt="boximg"/>
            <div className='box-content'>

            <h5>Complete Assistance:</h5><p>
            
This network includes parents, college representatives, psychologists, and psychiatrists, providing 24x7 online counseling through a visual help desk. Additional support encompasses medicine delivery, medication monitoring, and alert generation.

</p>
        </div></div>

       

    </div>
</div>

    );
};
export default Solution;