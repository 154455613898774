import React from 'react';
import './Footer.css'; 
import moblogo from "../../images/moblogo.png";
const Footer = () => {
  return (
    <footer className="footer-container">
        <div className='footer-columns'>
            <div className="column1">
                
                <img className='moblogo' src={moblogo} alt="moblogo"/>
                <p>Our mission is to Empower Healthcare Stakeholders Through Innovative Products,<br/> Services And Solutions That Make High-Quality Healthcare Affordable <br/>And Accessible For Everyone.</p>
                </div>
            <div className="column2">
                    
                    </div>
        </div>
        <div class="copyrights">
            <h6>&copy; All Rights Reserved Mobileixion Technologies Pvt. Ltd. 2024 </h6>
        </div>
    </footer>
  );
};

export default Footer;
