
import './App.css';
import Homepage from "./Pages/Homepage/homepage";
import Nav from  "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Contact from './Pages/Contact-us/contactus';
import Psycatrist from './Pages/Psycatrist/Psycatrist';
import Psychologist from "./Pages/Psychologist/Psychologist";
import Educational_inst from "./Pages/Educational_inst/Educational_inst";
import Counselling_inst from "./Pages/Counselling_inst/Counselling_inst";
import { Route, Routes } from 'react-router-dom';


function App() {

 
  return (
    <>
    <Nav />
    <Routes scrollRestoration="auto">
      <Route path="/" element={<Homepage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/Psycatrist" element={<Psycatrist />} />
      <Route path="/Psychologist" element={<Psychologist />} />
      <Route path="/Educational_inst" element={<Educational_inst />} />
      <Route path="/Counselling_inst" element={<Counselling_inst />} />
    </Routes>
  

    

    
   

    <Footer/>
    </>
  );
}

export default App;
